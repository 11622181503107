import React from "react";
import huawei1 from "../../../../static/news-images/huawei1.png";
import huawei2 from "../../../../static/news-images/huawei2.jpg";
import huawei3 from "../../../../static/news-images/huawei3.jpg";

import Layout from "../../../components/Layout/Layout";

const Article17092021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">HUAWEI IdeaHub </h1>
            <p>
              a productivity tool for the smart office — bundles intelligent
              writing, High Definition (HD) video conferencing and wireless
              sharing. A Red Dot Award 2020 winner, IdeaHub is designed to suit
              any environment. Effortlessly turn conference rooms, executive
              offices, and open areas into smart spaces.
            </p>

            <img className="news-image" src={huawei1} alt="" />

            <p className="bold">The Elegance of Simplicity</p>
            <p>
              A sleek, contemporary, user-centric design. A slender, minimalist
              stand. IdeaHub effortlessly integrates into any space.
            </p>
            <p className="bold">Say Goodbyeto Cables</p>
            <p>
              Combined 4K and H.265 technologies deliver a crisp, clear, smooth
              experience. Simply tap to share what's on your laptop or phone.
              On-screen real-time page turning and annotation make truly
              flexible collaboration simply effortless.
            </p>

            <img className="news-image" src={huawei2} alt="" />

            <p className="bold">As Easy as Paper</p>
            <p>
              With 35 ms ultra-low writing latency, enjoy a smooth,
              ultra-responsive writing experience. Innovative intelligent
              writing recognition automatically identifies words, figures, and
              flowcharts. Pen and Paper A dual-System-on-a-Chip (SoC) design
              combines independent graph computing, Artificial Intelligence
              (AI)-powered computing, and the audio and video codec engine. The
              end result? Quality conferencing supported by simplified meeting
              operations.
            </p>

            <p className="bold">Crystal Clear</p>
            <p>
              An array of 12 microphones uses beamforming technology to pick-up
              human voices over an 8 m radius. Wider frequency for transfers
              ensures crystal clear, Hi-Fi audio at every remote site.
            </p>

            <img className="news-image" src={huawei3} alt="" />

            <p className="bold">The Center of Attention</p>
            <p>
              With fast, accurate tracking, the speaker is always the center of
              attention. AutoFrame automatically adjusts the camera angle based
              on the conference room and the number of participants. Enjoy an
              optimal panoramic view, whenever, wherever.
            </p>

            <p className="bold">Stay Connected from Anywhere</p>
            <p>
              With just a single tap, send whiteboard content and fresh ideas
              flying from the hub to remote sites. Real-time collaboration
              becomes a real-world reality.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article17092021;
